@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.site_header {
  width: 100%;
  background: hsl(0, 100%, 100%);
  border-bottom: 1px solid hsl(0, 0%, 86%);
}

.site_header .navbar {
  background: transparent;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  align-items: stretch;
}

.site_header .navbar-start {
  align-items: center;
}

.button {
  border-radius: 50px;
  padding: 0 1.5rem;
}

.has-text-info {
  color: hsl(150, 50%, 40%);
}

.is-info,
.button.is-info {
  background: hsl(150, 50%, 40%);
}

.button.is-info:hover {
  background: hsl(150, 50%, 30%);
}

.button.is-info:disabled {
  background: hsl(150, 50%, 15%);
}

.is-info-dark {
  background: hsl(150, 50%, 15%);
}

.button.is-info.is-outline {
  background: transparent;
  border: 2px solid hsl(150, 50%, 40%);
  color: hsl(150, 50%, 40%);
}

.ml-space {
  margin-left: 0.5rem;
}

.form-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  height: 100%;
}

.form {
  width: 100%;
  max-width: 35rem;
  background: white;
  padding-top: 2rem;
  border-radius: 0.5rem;
  padding: 3rem;
}

.formInput {
  transition: 0.4s ease;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid hsl(150, 0%, 90%);
  box-shadow: none;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.form-helper {
  display: inline-block;
  margin-bottom: 2rem;
}

.form .title.is-1 {
  margin-bottom: 1rem;
}

.form-top-error {
  margin: 0;
  margin-bottom: 1rem;
}

.profile-header {
  position: relative;
  overflow: hidden;
}

.profile-header .container {
  display: flex;
  align-items: flex-start;
}

.profile-background {
  background: #f7f7f7;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.profile-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-image {
  background: hsl(0, 0%, 80%);
  /* border: 2px solid white; */
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details .title {
  line-height: 1;
  padding: 0;
  margin-bottom: 0.5rem;
}

.has-backdrop {
  display: inline-block;
  background: hsla(0, 0%, 0%, 0.8);
  padding: 0.5rem;
  color: white;
  max-width: 400px;
}

.title.has-backdrop {
  padding: 0.2rem 0.5rem 0.5rem;
}

.create-post-link {
  border-left: 1px solid hsl(0, 0%, 89%);
}

.user-nav.open .user-nav--identity {
  background: rgba(109, 40, 217, 1);
  color: white;
}

.user-nav--username {
  margin-right: 0.5rem;
}

.user-nav--identity i {
  font-size: 0.8rem;
}

.user-nav--dropdown {
  display: none;
}

.user-nav.open .user-nav--dropdown {
  display: block;
}

button.user-nav--link {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.user-nav--identity:hover,
.user-nav--link:hover {
  background: hsl(0, 16%, 96%);
}

.profile {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.posts {
  /* background: hsl(0, 0%, 96%); */
  max-width: 75%;
  flex: 1;
}

.post-preview {
  background: white;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.post-header {
  margin-bottom: 1rem;
}

.post-footer {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.profile-header.skeleton,
.skeleton span,
h2.skeleton {
  background: hsl(0, 0%, 90%);
}

h2.skeleton,
.skeleton span {
  display: block;
  width: 100%;
  height: 25px;
}

h1.skeleton {
  height: 60px;
  max-width: 400px;
  background: hsl(0, 0%, 90%);
}

h2.skeleton {
  max-width: 500px;
}

.tags.skeleton span {
  max-width: 60px;
}

.post-excerpt.skeleton span {
  margin-bottom: 0.5rem;
  max-width: 400px;
}

.post-excerpt.skeleton span:nth-child(2) {
  max-width: 250px;
}

.post-footer.skeleton span {
  max-width: 100px;
}

input.button {
  margin-top: 2rem;
}

.rounded {
  border-radius: 10px;
}

.password-reset .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-reset-form {
  background: white;
  padding: 4rem;
  border-radius: 10px;
  max-width: 500px;
}

.password-reset-form button {
  margin-top: 1rem;
  width: 100%;
}

.password-reset-form span {
  margin-bottom: 1rem;
}

.capitalise {
  text-transform: capitalize;
}

.comment {
  padding: 1rem;
}

.comment-box {
  margin-top: 2rem;
}

.comment-box .container {
  padding-bottom: 2rem;
  padding: 2rem 4rem;
  background: #f7f7f7;
  border-radius: 25px;
}

.comment-content {
  background: #f7f7f7;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 10px;
}

.comment-author {
  margin-bottom: 0.5rem;
}

.comment-author a:hover {
  text-decoration: underline;
}

.comment-tag {
  margin-right: 0.3rem;
}

.comment-avatar {
  overflow: hidden;
  border-radius: 50px;
  border: 1px solid rgba(200, 200, 200, 0.4);
}

.comment-avatar img {
  object-fit: cover;
  height: 100%;
}
